/*
#0D6EFD Blue
#198754 Green
#FFC107 Yellow
*/

@font-face {
  font-family: 'comfortaaregular';
  src: url('./fonts/comfortaa-variablefont_wght-webfont.woff2') format('woff2'),
  url('./fonts/comfortaa-variablefont_wght-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html {
  position: relative;
  min-height: 100%;
  margin:0;
  padding:0;
  font-weight:bold;
}

body {
  background: url("./images/commercial2.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

nav {
  font-family: 'comfortaaregular';
  font-weight:bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.frontpage-banner {
  min-height: 300px;
  color: #fff;
}

.frontpage-card {
  min-height: 460px;
}

.btn-disabled {
  pointer-events: none;
  opacity: 75%;
}